import request from '@/util/request'

// 获取 区域
export function getAdminAreaList(parentCode, level) {
  return request({
      url: `/app/city/city/adminAreaList?parentCode=${parentCode}&level=${level}`,
      method: 'get'
  })
}

// 获取 【巴林右旗】 的所有 【镇（苏木）】
export function getTownList(countyId) {
  return request({
      url: `/app/city/city/townList?countyId=${countyId}`,
      method: 'get'
  })
}

// 获取 【巴林右旗 - 某一个乡镇】 的所有 【嘎查】 
export function getVillageList(townId) {
  return request({
      url: `/app/city/city/villageList?townId=${townId}`,
      method: 'get'
  })
}

// 获取 【巴林右旗 - 某一个乡镇 - 某一个嘎查】 的所有 【小组】
export function getGroupListByVillageId(villageId) {
  return request({
      url: `/app/animal/bigData/getGroupListByVillageId?villageId=${villageId}`,
      method: 'get'
  })
}

// 获取 镇（苏木）详情
export function getTownInfo(townId) {
  return request({
      url: `/app/city/city/getTownInfo?townId=${townId}`,
      method: 'get',
  })
}

// 获取 村（嘎查）详情
export function getVillageInfo(villageId) {
  return request({
      url: `/app/city/city/getVillageInfo?villageId=${villageId}`,
      method: 'get',
  })
}

// 获取 区域 存栏、防疫、防疫员、牧户
export function getCountStatistics(data) {
  return request({
      url: `/app/animal/bigData/getCountStatistics`,
      method: 'post',
      data
  })
}

// 获取 全旗 动防站点
export function getSiteList() {
  return request({
      url: `/app/animal/bigData/getSiteList`,
      method: 'get',
  })
}

// 获取 动防站点 负责人信息
export function getHeadInfoBySiteId(headId) {
  return request({
      url: `/app/animal/bigData/getHeadInfoBySiteId?headId=${headId}`,
      method: 'get',
  })
}