module.exports  = {
  // ------------------------ 开发环境 ------------------
  // baseURL: '/fy/bigData2',

  // -------------------------- 生产环境 ---------------------
  baseURL: 'https://animalbig.5mus.com/bigData2',


  // file Path
  filePath: 'https://animalep.5mus.com'
}