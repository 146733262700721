import request from '@/util/request'

function filterNUll(d) {
  let param2 = {};
  for (let k in d){
    // console.log(d[k])
    if(d[k] != null){
      param2[k] = d[k];
    }
  }
  return param2;
}

function makeParams(d) {
  var list = []
  var paramList = Object.entries(d);
  for (let k in paramList){
    // console.log(k, paramList[k]);
    list.push(`${paramList[k][0]}=${paramList[k][1]}`);
  } 
  return list.join("&")
}

// ------------------ 王志厅 Api -----------------------------

// 获取 动防站 列表
// export function getRecordsYiStatistics(data) {
//   return request({
//       url: `/app/animal/bigData/getRecordsYiStatistics`,
//       method: 'post',
//       data
//   })
// }

// 获取 地区、牧户 的 存栏、防疫 统计
export function getRecordsYiStatistics(data) {
  return request({
      url: `/app/animal/bigData/getRecordsYiStatistics`,
      method: 'post',
      data
  })
}

// 获取 防疫列表 (全旗 防疫 治疗 总数) 
export function getRecordsYiCount(townId) {
  return request({
    url: `/app/animal/bigData/getRecordsYiCount?townId=${townId}`,
    method: 'get'
  })
}

// 获取 疫苗树
export function getVaccinumTree(vaccinumName=null) {
  if (vaccinumName) {
    return request({
      url: `/app/animal/bigData/getVaccinumTree?vaccinumName=${vaccinumName}`,
      method: 'get'
    })
  } else {
    return request({
      url: `/app/animal/bigData/getVaccinumTree`,
      method: 'get'
    })
  }
}

// 获取 防疫工作 列表
export function getJobList() {
  return request({
      url: `/app/animal/bigData/getJobList`,
      method: 'get'
  })
}

// 获取 防疫工作2 列表
export function getJobList2() {
  return request({
      url: `/app/animal/bigData/getJobList2`,
      method: 'get'
  })
}

// ------------------ 阿荣 Api -----------------------------
// 获取 免疫台账 数据 
export function getRecordsYiStatisticByParentAreaCode(params) {
  let p = filterNUll(params)
  let query = makeParams(p)
  
  // let query = `
  //   parentAreaCode=${params.parentAreaCode ? params.parentAreaCode : ''}
  //   &areaCode=${params.areaCode ? params.areaCode : ''}
  //   &parentLevel=${params.parentLevel ? params.parentLevel : ''}
  //   &jobId=${params.jobId ? params.jobId : ''}
  //   &vaccinumId=${params.vaccinumId ? params.vaccinumId : ''}
  //   &animalId=${params.animalId ? params.animalId : ''}
  // `

  return request({
    url: `/app/animal/bigData/getRecordsYiStatisticByParentAreaCode?${query}`,
    method: 'get',
  })
}

// 分页获取 地区 免疫台账 数据 
export function getMytzPage(data) {
  return request({
      url: '/bigdata/animal/mytz/page',
      method: 'post',
      data
  })
}

// 获取 免疫记录
export function getRecordsYiPage(data) {
  return request({
      url: '/bigdata/animal/recordsYi/page',
      method: 'post',
      data
  })
}

// 获取 免疫台账 数据 
export function filterRecordsYiCount(data) {
  return request({
      url: '/app/animal/bigData/getRecordsYiList',
      method: 'post',
      data
  })
}


// 获取 地区 的 存栏、防疫 统计
export function getRecordsYiStatisticByAreaCode(areaCode,  level, jobId) {
  return request({
      // url: `/app/animal/bigData/getRecordsYiStatisticByAreaCode?areaCode=${areaCode}&level=${level}&vaccinumTypeId=${vaccinumTypeId}`,
      url: `/app/animal/bigData/getRecordsYiStatisticByAreaCode?areaCode=${areaCode}&level=${level}&jobId=${jobId}`,
      method: 'get'
  })
}

// 获取 牧户的 存栏、防疫 统计
export function getStatisticByUserId(userId, jobId) {
  return request({
      url: `/app/animal/bigData/getStatisticByUserId?userId=${userId}&jobId=${jobId}`,
      method: 'get'
  })
}

// 获取 免疫台账
export function getRecordsYiListByVillageId(data) {
  return request({
      url: `/app/animal/bigData/getRecordsYiListByVillageId`,
      method: 'post',
      data
  })
}