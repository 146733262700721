import request from '@/util/request'

function filterNUll(d) {
  let param2 = {};
  for (let k in d){
    // console.log(d[k])
    if(d[k] != null){
      param2[k] = d[k];
    }
  }
  return param2;
}

function makeParams(d) {
  var list = []
  var paramList = Object.entries(d);
  for (let k in paramList){
    // console.log(k, paramList[k]);
    list.push(`${paramList[k][0]}=${paramList[k][1]}`);
  } 
  return list.join("&")
}

// 获取 防疫员 列表
export function getAntiepidemicPersonList(siteId, jobId, name) {
  var query = ''
  if (name) {
    query = siteId === null || siteId === undefined ? '' : `?siteId=${siteId}&jobId=${jobId}&userName=${name}`
  } else {
    query = siteId === null || siteId === undefined ? '' : `?siteId=${siteId}&jobId=${jobId}`
  }
  return request({
    url: `/app/animal/bigData/getAntiepidemicPersonList${query}`,
    method: 'get'
  })
}

// 获取 防疫员 签到列表
export function getSignInList() {
  return request({
    url: `/app/animal/bigData/getSignInList`,
    method: 'get'
  })
}

// 获取 防疫员 签到信息
export function getAntiepidemicPersonSignIn(id, date) {
  return request({
    url: `/app/animal/bigData/getAntiepidemicPersonSignIn?id=${id}&date=${date}`,
    method: 'get'
  })
}

// 获取 防疫员详情
export function getAntiepidemicPersonInfo(userId) {
  return request({
    url: `/app/animal/bigData/getAntiepidemicPersonInfo?id=${userId}`,
    method: 'get'
  })
}

// 获取 防疫员 防疫统计
export function getRecordsYiStatisticsByPersonId(userId) {
  return request({
    url: `/app/animal/bigData/getRecordsYiStatisticsByPersonId?antiepidemicPersonId=${userId}`,
    method: 'get'
  })
}

// 获取 防疫员 疫苗使用量
export function getRecordsYiStatisticsByAnimalId(data) {
  return request({
    url: `/app/animal/bigData/getRecordsYiStatisticsByAnimalId`,
    method: 'post',
    data
  })
}

// 获取 防疫员 防疫的【牧户】列表
export function getRecordsYiListByPersonId(personId, jobId, animalId, vaccinumId) {
  return request({
    url: `/app/animal/bigData/getRecordsYiListByPersonId?antiepidemicPersonId=${personId}&jobId=${jobId}&animalId=${animalId}&vaccinumId=${vaccinumId}`,
    method: 'get'
  })
}

// 获取 防疫员 防疫的牧场列表
// export function getFarmListByUserId(userId) {
//   return request({
//     url: `/app/animal/bigData/getFarmListByUserId?id=${userId}`,
//     method: 'get'
//   })
// }

// 获取 防疫员 防疫 列表
export function getRecordsYiListByUserId(data) {
  return request({
    url: `/app/animal/bigData/getRecordsYiListByUserId?id=${data.userId}&farmId=${data.farmId}`,
    method: 'get'
  })
}