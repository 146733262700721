<template>
  <div class="header_bgi2 w_full h_50 lh_40 text_center bgs_full">
    <!-- 防疫工作 -->
    <div class="w_30_p select_global_job text_left absolute top_5 left_20">
      <el-select 
        class="" 
        v-model="jobId" 
        size="small" 
        placeholder="防疫工作" 
      >
        <el-option
          v-for="(item, index) in jobList" :key="index"
          :label="item.name"
          :value="item.id"
          @click.native="selectJob(item)"
        >
        </el-option>
      </el-select>
    </div>

    <div 
      class="fs_26 fw_bold color_fff cursor font_zh_en" 
      @click="goToHome"
    >
      {{ headerName }}
    </div> 
  </div>
</template>

<script>
  import { getJobList2 } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'BigScreenHeader',
    props: {
      headerName: ''
    },
    data() {
      return {
        jobId: null,
        jobName: null,
        jobList: []
      }
    },
    async mounted() {
      await this.getJobList()

      let sessionJob = JSON.parse(sessionStorage.getItem('job'))

      this.jobId = sessionJob.jobId
      this.jobName = sessionJob.jobName

      this.$emit('selectJob', sessionJob.jobId)
    },
    methods: {
      // 获取 防疫工作、盘点 列表
      async getJobList() {
        let res = await getJobList2()
        try {
          if (res.code == 1000) {
            let jobList = res.data.filter(item => item.isType == 1)
            this.jobList = jobList
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 监听 全局 防疫工作
      selectJob(item) {
        this.jobId = item.id

        let sessionJob = {jobId: item.id, jobName: item.name}
        sessionStorage.setItem('job', JSON.stringify(sessionJob))

        this.$emit('selectJob', item.id)
      },

      // 跳转到 首页
      goToHome() {
        let json_role = sessionStorage.getItem('role')
        let role = JSON.parse(json_role)
        let path = role.id == 1 ? '/' : `/erpSite/${role.id}/${role.name}/${role.managerId}`
        
        this.$router.push({
          path
        })
      },
    }
  }
</script>

