<template>
  <div 
    :id="id" 
    :class="className" 
  >
  </div>
</template>

<script>
// import tdTheme from './theme.json' // 引入默认主题
import './balinyouqi.js'

export default {
  name: 'echart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '2.5rem'
    },
    height: {
      type: String,
      default: '2.5rem'
    },
    options: {
      type: Object,
      default: ()=>({})
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    options: {
      handler (options) {
        // 设置true清空echart缓存
        this.chart.setOption(options, true)
      },
      deep: true
    }
  },
  mounted() {
    // this.$echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
    // this.initChart();
  },
  beforeDestroy() {
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      // 初始化echart
      this.chart = this.$echarts.init(this.$el, 'tdTheme')
      this.chart.setOption(this.options, true)
    },
  }
}
</script>

<style lang="less" scoped>

.h_map_box {
  width: 100%;
  height: 60%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
