<template>
  <div class="full text_center">
    <Chart class="text_center" :cdata="cdata" @clickMap="clickMap" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  components: {
    Chart,
  },
  data () {
    return {
      cdata: [
        {
          name: '巴林右旗',
          value: 10,
        },
        {
          name: '索博日嘎镇',
          value: 9,
        },
        {
          name: '幸福之路苏木',
          value: 8,
        },
        {
          name: '巴彦琥硕镇',
          value: 7,
        },
        {
          name: '查干沐沦苏木',
          value: 6,
        },
        {
          name: '大板镇',
          value: 5,
        },
        {
          name: '大板煤电化基地',
          value: 4,
        },
        {
          name: '巴彦塔拉苏木',
          value: 3,
        },
        {
          name: '查干诺尔镇',
          value: 2,
        },
        {
          name: '宝日勿苏镇',
          value: 1,
        },
        {
          name: '西拉沐沦苏木',
          value: 0,
        }
      ]
    }
  },
  mounted () {
    
  },
  methods: {
    clickMap(val) {
      this.$emit('clickMap', val)
    }
  }
}
</script>

<style lang="less" scoped>
</style>