<template>
  <div class="box">
    <div class="zhu_zhuang_tu_box">
      <v-chart :options="options" autoresize/>
    </div>
  </div>
</template>

<script>

export default {
  name: "ZhuZhuangTuList",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean
  },
  data() {
    return {
      options: {
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['product', '2015', '2016', '2017'],
          source: [
            { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
            { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
            { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
            { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
          ]
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          { 
            type: 'bar',
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    "#c05bdd",
                    "#F6BD16",
                    "#f29e3c"
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            }
          },
          { 
            type: 'bar',
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    "#c05bdd",
                    "#F6BD16",
                    "#f29e3c"
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            }
          },
          { 
            type: 'bar',
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    "#c05bdd",
                    "#F6BD16",
                    "#f29e3c"
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            }
          },
        ]
      }
    }
  },
  watch: {
    // value(val) {
    //   if (val.xRotate) {
    //     this.options.xAxis.axisLabel.rotate = 0
    //   }
    //   this.options.xAxis.data = val.x
    //   this.options.yAxis.data = val.y
    //   this.options.series[0].data = val.y
    //   this.options.yAxis.name = val.yName
    // }
  },
  mounted() {
    // let val = JSON.parse(this.$route.params.val)
    // this.options.title.text = val.title
    // this.options.xAxis.data = val.x
    // this.options.yAxis.data = val.y
    // this.options.yAxis.name = val.yName
    // this.options.series[0].data = val.y
  },
  methods: {
    
  }
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhuang_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 5px; color: #fff; font-size: 14px; }
</style>
