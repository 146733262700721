import { render, staticRenderFns } from "./bing-tu.vue?vue&type=template&id=324fc1e0&scoped=true"
import script from "./bing-tu.vue?vue&type=script&lang=js"
export * from "./bing-tu.vue?vue&type=script&lang=js"
import style0 from "./bing-tu.vue?vue&type=style&index=0&id=324fc1e0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324fc1e0",
  null
  
)

export default component.exports