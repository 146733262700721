<template>
  <div class="box">
    <div class="zhu_zhuang_tu_box">
      <v-chart :options="options" autoresize @click="clickChart" />
    </div>

    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>

export default {
  name: "ZhuZhuangTuX",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean
  },
  data() {
    return {
      unit: '',
      vaccinumList: [],
      animalList: [],
      options: {
        title: {
          text: '',
          top: '',
          left: '40px',
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: '30px'
          }
        },
        grid: {
          left: "2%",
          right: "15%",
          top: "10%",
          bottom: "7%",
          containLabel: true,
        },
        legend: {},
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          data: [],
          name: '',
          splitLine: false,
          axisTick: {
		        alignWithLabel: true, //柱状图中心是否对齐刻度位置
		        show: false //不显示刻度
		      },
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          }
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            width: 200,
            owerflow: 'break',
            textStyle: {
              color: "#fff",
              fontSize: 15,
              fontWeight: '500'
            },
            // rotate: -45
          },
        },
        series: [
          {
            color:[
              '#0874EE',
              '#E99E00',
              '#00C1F5',
              '#FF00D1',
              '#FFFFFF',
              '#00E259'
            ],
            data: [],
            type: "bar",
            barGap: "100%",
            barCategoryGap: "200%",
            barWidth: 10,
            color: '#2366fc',
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    'rgb(106, 240, 177)',
                    'rgb(9, 117, 240)',
                    'rgb(227, 233, 240)',
                    'rgb(247, 183, 56)',
                    'rgb(137, 240, 106)',
                    'rgb(245, 138, 221)',
                    'rgb(224, 85, 85)',
                    'rgb(222, 178, 18)',
                    'rgb(130, 245, 68)',
                    'rgb(155, 87, 249)',
                    'rgb(87, 122, 249)'
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },

                // 渐变色
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                //   [
                //     {
                //       offset: 0,
                //       color: "rgba(0,244,255,1)" // 0% 处的颜色
                //     },
                //     {
                //       offset: 1,
                //       color: "rgba(0,77,167,1)" // 100% 处的颜色
                //     }
                //   ],
                //   false
                // ),
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                // shadowBlur: 4,
                // 圆角
                // barBorderRadius: [0, 8, 8, 0]
              }
            },
            // 标签
            label: {
              show: true,
              position: "right",
              distance: 10,
              fontSize: 16,
              color: '#fff',
              fontWeight: 600,
              formatter: function(params) {
                return `{span|${params.value}}`
              },
              rich: {
                span: {
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: '',
                  letterSpacing: '2px'
                }
              }
            },
          }
        ],
      },
    };
  },
  watch: {
    value(val) {
      if (val.xRotate) {
        this.options.xAxis.axisLabel.rotate = 0
      }

      // debugger
      this.vaccinumList = val.vaccinumList

      this.options.xAxis.data = val.y
      this.options.yAxis.data = val.x
      this.options.series[0].data = val.y
      // this.options.yAxis.name = val.yName
      this.unit = val.unit
    }
  },
  mounted() {
    // let val = JSON.parse(this.$route.params.val)
    // this.options.title.text = val.title
    // this.options.xAxis.data = val.x
    // this.options.yAxis.data = val.y
    // this.options.yAxis.name = val.yName
    // this.options.series[0].data = val.y
  },
  methods: {
    clickChart(params) {
      if (this.vaccinumList && this.vaccinumList.length) {
        this.openVaccinumDetailPage(params)
      } else {
        this.clickYangYiYuanJob(params)
      }
    },

    // 打开 疫苗详情页
    openVaccinumDetailPage(params) {
      let id = ''
      let obj = {}

      this.vaccinumList.forEach(item => {
        if (params.name.substring(0, params.name.length -2) == item.name) {
          id = item.id
        }
      })

      obj = {
        id,
        name: params.name
      }

      this.$emit('emitChartParams', obj)
    },

    // 点击的 是 防疫员的 防疫工作
    clickYangYiYuanJob(params) {
      this.$emit('emitChartParams', params.name)
    }
  }
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhuang_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }

.s { color:rgb(87, 122, 249)}
</style>
