<template>
  <div class="full bgs_full chart_box_bgi_1">
    <h-map class="full" />
  </div>
</template>

<script>
  import HMap from '@/components/HMap/index.vue'

  export default {
    name: "Box1",
    components: { HMap },
    data() {
      return {

      }
    },
    props: {
      title: '',
      chartType: '',
      chartData: {}
    }
  }
</script>