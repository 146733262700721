<template>
  <div class="full" id="Echart" ref="Echart">
    <div class="w_full flex_row_between">
      <div 
        class="w_70_p text_left text_1 fw_bold text_1 cursor font_zh1 ls_2 jian_bian_text_1"  
        @click="goToPath(path)"
      >
        {{ title }}
      </div>

      <!-- 全屏 -->
      <div class="w_25 h_25" v-if="!isHideFullIcon">
        <img 
          class="full cursor" 
          v-if="isFullscreen" 
          src="~@/assets/images/chart-box/qu-xiao-quan-ping.png" 
          alt="" 
          @click="fullScreen()"
        >

        <img class="full cursor" v-if="!isFullscreen" src="~@/assets/images/chart-box/quan-ping.png" alt="" @click="fullScreen()">
      </div>
    </div>
    

    <div class="w_full h_full-30">
      <bing-tu 
        v-if="chartType == '饼图'" 
        :value="chartData" 
        @emitChartParams="emitChartParams" 
        @emitChartParamsMouseOver="emitChartParamsMouseOver" 
      />
      <ban-bing-tu 
        v-if="chartType == '半饼图'" 
        :value="chartData" 
        @emitChartParams="emitChartParams" 
      />
      <huan-zhuang-tu 
        v-if="chartType == '环状图'" 
        :isShowUnit="isShowUnit"
        :value="chartData" 
        @emitChartParams="emitChartParams" 
        @emitChartParamsMouseOver="emitChartParamsMouseOver" 
      />
      <zhu-zhuang-tu 
        v-if="chartType == '柱状图'" 
        :value="chartData" 
      />
      <zhu-zhuang-tu-2 
        v-if="chartType == '柱状图2'" 
        :value="chartData" 
      />
      <zhu-zhuang-tu-x 
        v-if="chartType == '柱状图3'" 
        :value="chartData" 
        @emitChartParams="emitChartParams" 
      />
      <shuang-zhu-tu 
        v-if="chartType == '双柱图'" 
        :value="chartData" 
        @emitChartParams="emitChartParams" 
      />
      <xian-xing-tu 
        v-if="chartType == '线性图'" 
        :value="chartData" 
      />
      <duo-xian-tu 
        v-if="chartType == '多线图'" 
        :value="chartData" 
      />
    </div>
  </div>
</template>

<script>
  import echartsMixin from '@/mixins/echart.js'
  import screenfull from 'screenfull'

  export default {
    name: "Echarts",
    mixins: [echartsMixin],
    props: {
      title: '',
      isShowUnit: true,
      isHideFullIcon: false,
      title_fs: 'text_1',
      path: '',
      chartType: '',
      bgi: 'default',
      chartData: {}
    },
    data() {
      return {
        isFullscreen: false,
      }
    },
    methods: {
      fullScreen() {
        // const element = document.getElementById('Echart');//指定全屏区域元素

        let element = this.$refs.Echart
        // 先使用screenfull.enabled方法判断是否支持screenfull

        // 如果不允许进入全屏，发出不允许提示 浏览器不能全屏
       if (!screenfull.isEnabled) {
          this.$message({
            message:'浏览器不能全屏，安装其他版本试试',
            type:'warning'
          })

          return false
        }
        
        this.isFullscreen = !this.isFullscreen
        
        screenfull.request(element)

        // 调用 screenfull.toggle() 可以双向切换全屏与非全屏
        screenfull.toggle()
      },

      goToPath(path) {
        this.$router.push({
          path
        })
      },

      // 向父组件 发送 图表参数
      emitChartParams(val) {
        this.$emit('emitChartParams', val)
      },

      // 监听鼠标经过 图标 扇形区域
      emitChartParamsMouseOver(val) {
        this.$emit('emitChartParamsMouseOver', val)
      }
    }
  }
</script>

<style>
#Echart:-webkit-full-screen {
  padding: 50px;
  background-color: #001531 !important;
}
#Echart:-moz-full-screen {
  padding: 50px;
  background-color: #001531 !important;
}
#Echart:-ms-fullscreen {
  padding: 50px;
  background-color: #001531 !important;
}
#Echart:fullscreen{
  padding: 50px;
  background-color: #001531 !important;
}
</style>