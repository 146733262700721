<template>
  <div class="box">
    <div class="zhu_zhuang_tu_box">
      <v-chart :options="options" autoresize />
    </div>

    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>

export default {
  name: "XianXingTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
  },
  data() {
    return {
      title: "",
      unit: '',
      options: {
        legend: {
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category", //类目轴
          boundaryGap: true, //坐标轴两边是否留白，默认是true
          data: [],
          textStyle: {
            color: "#fff"
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14
            },
            // rotate: -45
          }
        },
        yAxis: {
          type: "value", //可以不写，默认type:value
          textStyle: {
            color: "#fff"
          },
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: true,   // 是否显示 y轴 数值
            textStyle: {
              color: "#fff",
              fontSize: 14
            }
          }
        },
        series: [
          {
            //series-line
            data: [],
            color: '#2366fc',
            type: "line",
            // areaStyle: {}, //使用区域
            smooth: true, //圆润的折线
            itemStyle : { 
              normal: {
                label : {
                  show: true, 
                  color: '#fff',
                  fontSize: 14
                }
              }
            }, 
            markPoint: {
              //使用点
              // data: [
              //   { type: "max", name: "最大值" },
              //   { type: "min", name: "最小值" },
              // ],
            },
            // markLine: {
            //   //使用线
            //   data: [{ type: "average", name: "平均值" }],
            // },
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.options.xAxis.data = val.x
      this.options.yAxis.data = val.y
      this.options.series[0].data = val.y
      this.options.yAxis.name = val.yName
      this.unit = val.unit
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhuang_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }
</style>
