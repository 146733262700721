<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize @click="clickChart" @mouseover="mouseOverChart" />
    </div>

    <span v-show="isShowUnit" class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: "HuanZhuangTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
    isShowUnit: true
  },
  data() {
    return {
      unit: '',
      options: {
        title: {
          text: '',
          top: '0',
          left: '0',
          textStyle: {
            color: '#fff'
          },
          show: true
        },
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        grid: {
          left: "2%",
          right: "15%",
          top: "3%",
          bottom: "3%",
          containLabel: true,
        },
        // 分类说明
        legend: {
          top: "5%",
          left: "center",
          show: false,
          textStyle: {
            color: '#fff',
            fontSize: 14
          }
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   right: 0,
        //   top: 10,
        //   bottom: 0,
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: 14
        //   }
        // },
        hoverAnimation: true,
        series: [
          {
            type: "pie",
            color:[
              '#0874EE',
              '#E99E00',
              '#00C1F5',
              '#FF00D1',
              '#FFFFFF',
              '#00E259'
            ],
            left: '0%',
				    // center: ['30%', '50%'],     // [横坐标， 纵坐标]
            radius: ["50%", "57%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: 'rgb(0, 0, 0, 0)',
              borderWidth: 2,
            },
            label: {
              normal: {
                show: true,
                // position: "center",
                fontSize: 16,
                color: '#fff',
                formatter: function(params) {
                  return `{text|${params.data.name}}` + '\n' +
                   `{value|${params.data.value}}`
                    // + ' ' + `{text|${params.data.unit}}` 
                },
                rich: {
                  value: {
                    color: '#fff',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontFamily: '',
                    letterSpacing: '2px'
                  },
                  text: {
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: '500',
                    fontFamily: '',
                    letterSpacing: '2px',
                  },
                }
              },
            },
            labelLine: {
              show: true,
            },
            emphasis: {
              scale: true,  //是否开启高亮后扇区的放大效果。
              scaleSize: 15, //高亮后扇区的放大尺寸，在开启 emphasis.scale 后有效。
              label: {
                show: true,
                fontSize: 14,
                fontWeight: 'bold'
              },
              textStyle: {
                fontSize: '60',
                fontWeight: 'bold'
              }
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.options.series[0].data = val.data
      if (val.center) {
        this.options.series[0].center = val.center
      }
      
      this.unit = val.unit
    }
  },
  mounted() {},
  methods: {
    clickChart(params) {
      this.$emit('emitChartParams', params.data)
    },

    mouseOverChart(params) {
      this.$emit('emitChartParamsMouseOver', params.data)
    }
  },
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }

.s { color: #0a53f2; }
</style>
