<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize />
    </div>
  </div>
</template>

<script>

export default {
  name: "DuoXianTu",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
  },
  data() {
    return {
      title: "",
      options: {
        legend: {
          data: [],
          textStyle: {
            color: "#fff"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          textStyle: {
            color: "#fff"
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14
            },
            // rotate: -45
          }
        },
        yAxis: {
          type: 'value',
          textStyle: {
            color: "#fff"
          },
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14
            }
          }
        },
        series: []
      },
    };
  },
  watch: {
    value(val) {
      this.options.legend.data = val.legend
      this.options.xAxis.data = val.x
      this.options.series = val.series
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
}
.box {
  width: 100%;
  height: 100%;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
}


</style>
