<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize @click="clickChart" @mouseover="mouseOverChart" />
    </div>
    <!-- <span class="unit">单位: {{ unit }}</span> -->
  </div>
</template>

<script>

  export default {
    name: "bingTu",
    components: {},
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      ispreview: Boolean
    },
    data() {
      return {
        unit: '',
        options: {
          title: {
            text: "",
            left: "40px",
            top: '0',
            show: false,
            textStyle: {
              color: "#fff"
            }
          },
          // 提示
          tooltip: {
            trigger: "item",
            show: true,
          },
          legend: {
            orient: "vertical",
            left: "10px",
            top: '12%',
            show: false,
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              type: "pie",
              color:[
                'rgb(106, 240, 177)',
                'rgb(9, 117, 240)',
                'rgb(227, 233, 240)',
                'rgb(247, 183, 56)',
                'rgb(137, 240, 106)',
                'rgb(245, 138, 221)',
                'rgb(224, 85, 85)',
              ],
              radius: "50%",
              data: [],
              label: {
                normal: {
                  show: true,
                  fontSize: 16,
                  color: '#fff',
                  formatter: function(params) {
                  return params.data.name + '\n' 
                    + `{span|${params.data.value}}`
                    + ' ' + params.data.unit;
                  },
                  rich: {
                    span: {
                      color: '#52f355',
                      fontSize: '18px',
                      fontWeight: '700',
                      fontFamily: '',
                      letterSpacing: '2px'
                    }
                  }
                }
              },
              itemStyle: {
                borderRadius: 10,
                borderColor: 'rgb(0, 0, 0, 0)',
                borderWidth: 6
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        }
      };
    },
    watch: {
      value(val) {
        if (val.title == '人口统计') {
          this.options.title.show = false
        } else {
          this.options.title.text = val.title
        }

        this.options.series[0].data = val.data
        this.unit = val.unit
      }
    },
    mounted() {

    },
    methods: {
      clickChart(params) {
        this.$emit('emitChartParams', params.data)
      },

      mouseOverChart(params) {
        this.$emit('emitChartParamsMouseOver', params.data)
      }
    }
  };
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }
</style>
