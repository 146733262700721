<template>
    <div class="full flex_row_between">
      <div class="w_45 h_full text_center flex_center title_bgi2 bgs_full ph_10 border_box">
        <span class="jian_bian_text_3 text_1 color_fff fw_bold">巴林右旗动物动防站点</span> 
      </div>

      <div class="w_full-50 h_full">
        <fang-yi-site-person :jobId="jobId" />
      </div>
    </div> 
    
  </template>
  
  <script>
    import fangYiSitePerson from './fang-yi-site-person.vue';

    export default {
      props: {
        jobId: null
      },
      components: { fangYiSitePerson },
      name: 'FangYiSite',
    }
  </script>
  
  