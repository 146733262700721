<template>
  <div class="w_full_i">
    <el-cascader
      ref="cascader"
      class="w_full_i"
      :props="props"
      size="small"
      v-model="selectedOptions"
      placeholder="请选择行政区域"
      clearable
      @change="handleChange"
    />
  </div>
</template>

<script>
import { getAdminAreaList } from '@/api/base/base.js'

export default {
  name: 'cascaderArea',
  data() {
    return {
      townList: [],
      selectedOptions: [],

      props: {
        multiple: false, 
        checkStrictly: true,
        lazy: true,
        lazyLoad: (node, resolve) => {
          let { level } = node;
          const nodes = []  // 动态节点

          let areaCode = null

          if (!level) {
            areaCode = "150423"
            level = 3
          } else {
            areaCode = node.value
            level = level + 3
          }

          // console.log('level : ', level);

          if (level >= 5) return false

          this.getAdminAreaList(areaCode, level)
          .then((res) => {
            res.map((item) => {
              let area = {
                value: item.code,
                label: item.name,
                leaf: node.level >=5,
              };

              // debugger
              nodes.push(area)
            });
            resolve(nodes) // 回调
          })
          .catch((error) => {
            console.log(error)
          })
        },
      }
    }
  },
  watch: {
    
  },
  mounted() {
    this.$bus.$on('cascaderClearable', () => {
      this.selectedOptions = []
    })
  },
  methods: {
    // 获取 区域
    async getAdminAreaList(code, level) {
      let res = await getAdminAreaList(code, level)
      try {
        if (res.code == 1000) {
          return res.data 
        }
      } catch(err) {
        console.log(err)
      }
    },

    // 选择的行政区
    handleChange(val) { 
      if (val.length) {
        let list = []
        let labeList = this.$refs['cascader'].getCheckedNodes()[0].pathLabels

        for (let i in val) {
          let label = labeList[i]

          list.push({ code: val[i], label})
        }

        this.$emit('getSelectedOptions', list)
      } else {
        this.$emit('initOptions')
      }
    },
  }
}
</script>
