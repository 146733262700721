// 大屏 Header
import BigScreenHeader from '@/common/header/index.vue'

import Cascader from '@/common/cascader.vue';

import Echart from '@/components/div/echart.vue';
import EchartMap from '@/components/div/echart-map.vue';

import fangYiSite from '@/components/div/fang-yi-site/index.vue';
import fangYiSitePerson from '@/components/div/fang-yi-site/fang-yi-site-person.vue';

import HMap from '@/components/HMap/index.vue'

export default {
  components: {
    BigScreenHeader,
    Cascader,
    Echart,
    EchartMap,
    fangYiSite,
    fangYiSitePerson,
    HMap
  }
}