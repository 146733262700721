<template>
  <div class="box">
    <div class="zhu_zhuang_tu_box">
      <v-chart :options="options" autoresize @click="clickChart" />
    </div>

    <!-- <span class="unit">单位: {{ unit }}</span> -->
  </div>
</template>

<script>
const labelOption = {
  show: true,
  rotate: 90,
  formatter: '{c}  {name|{a}}',
  fontSize: 16,
  rich: {
    name: {}
  }
};
export default {
  name: "ShuangZhuTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isShowUnit: true,
    isShowLegend: true,
    isShowXData: true
  },
  data() {
    return {
      animalList: [],
      unit: '',
      options: {
        title: {
          text: '',
          top: '',
          left: '40px',
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: '30px'
          }
        },
        grid: {
          left: "2%",
          right: "15%",
          top: "0%",
          bottom: "0%",
          // y 轴 分类名称 全部显示
          containLabel: true
        },
        legend: {
          bottom: -5,
          right: 20,
          textStyle: {
            color: '#fff'
          }
        },
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          data: [],
          name: '',
          splitLine: false,
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          }
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 15,
              fontWeight: '500'
            },
            // rotate: -45
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 8,
            barGap: "50%",
            barCategoryGap: "0%",
            color: '#0874EF',

            itemStyle: {
              normal: {
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                // shadowBlur: 2,
                // 圆角
                // barBorderRadius: [0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            // 标签
            label: {
              show: true,
              position: "right",
              distance: 10,
              fontSize: 16,
              color: '#fff',
              fontWeight: 600,
              formatter: function(params) {
                return `{span|${params.value}}`
              },
              rich: {
                span: {
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: '',
                  letterSpacing: '10px'
                }
              }
            }
          },
          {
            data: [],
            type: "bar",
            barWidth: 8,
            barGap: "100%",
            barCategoryGap: "100%",
            color: '#42C028',
            itemStyle: {
              normal: {
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                // shadowBlur: 2,
                // 圆角
                // barBorderRadius: [0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            // 标签
            label: {
              show: true,
              position: "right",
              distance: 10,
              fontSize: 16,
              color: '#fff',
              fontWeight: 600,
              formatter: function(params) {
                return `{span|${params.value}}`
              },
              rich: {
                span: {
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: '',
                  letterSpacing: '4px'
                }
              }
            },
          }
        ],
        // 提示
        tooltip: {
          trigger: "axis",
          show: false,
          textStyle: {
            type: 'cross'
          }
        }
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        // 点击 双柱图 的 【防疫】线条时 会用到 animalList 中的  item.id, 请看 方法 clickChart()
        val.animalList.forEach(item => {
          this.options.xAxis.data.push(item.name)
        });

        this.animalList = val.animalList

        this.options.yAxis.data = val.x
        this.options.series[0].id = val.data1.id
        this.options.series[0].name = val.data1.name
        this.options.series[0].data = val.data1.data

        this.options.series[1].name = val.data2.name
        this.options.series[1].data = val.data2.data
        this.unit = val.unit
      },
      
      deep: true
    } 
  },
  mounted() {

  },
  methods: {
    clickChart(params) {
      let id = ''

      this.animalList.forEach(item => {
        if (params.name == item.name) {
          id = item.id
        }
      })

      let obj = {
        id,
        name: params.name
      }

      this.$emit('emitChartParams', obj)
    }
  }
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // border: 1px solid #f00;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhuang_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid #f00;
}

.unit { position: absolute; left: 10px; bottom: 5px; color: #fff; font-size: 14px; }

.s { color: #80efa0; }

</style>
