<template>
  <div class="full flex_column_between pv_40 pl_20 border_box chart_box_bgi_2 relative" @mouseleave="activeIndex = null">
    <div 
      class="w_40_p h_full absolute top_0 right_0 overflow_y_auto scroll_box border_1_s_002B67 chart_box_bgi_2 z_99999" 
      v-show="activeIndex !== null"
      @mouseleave="activeIndex = null"
    >
      <div class="w_full h_40 lh_40 text_1 fw_bold title_bgi1 bgs_full color_fff text_center">
        防疫员列表
      </div>
      <div 
        class="text_1 pv_10 cursor color_fff"
        v-for="(item, item_index) in antiepidemicPersonList" :key="item_index"
        @click="goToAntiepidemicPersonListPage(item.id)"
      >
        <div class="pl_10">
          · <span class="ml_5"></span>
          {{ item.name }}
          <!-- <span class="ml_10"></span>
          {{ item.recordCount }} -->
        </div>
      </div>
    </div>

    <div 
      class="w_full cursor flex_start" 
      v-for="(site, site_index) in siteList" :key="site_index"
      @click="goToSite(site.id, site.name, site.headId)"
    >
      <div 
        class="w_full flex_row_start text_1"
        :class="{ active_color: activeIndex == site_index }"
        @mouseover="handleMouseoverSiteName(site_index, site.id)"
      >
        <div class="w_40 font_number fw_bold jian_bian_text_3 hover_color_fff">{{ site_index + 1 }}.</div> 
        <div 
          class="w_full fw_500 ml_5 color_A1C6F2 hover_color_fff ls_3 border_b_1_s_001531"
          :class="{ border_b_1_s_002B67: activeIndex == site_index }"
        >
          {{ site.name + '动防站' }}
        </div> 
      </div>
    </div>
  </div> 
  
</template>

<script>
  import { getSiteList } from '@/api/base/base.js'
  import { getAntiepidemicPersonList } from '@/api/fang-yi/fang-yi-yuan.js'

  export default {
    name: 'FangYiSitePerson',
    props: {
      jobId: null
    },
    data() {
      return {
        activeIndex: null,
        siteList: [],                   // 巴林右旗 所有动防站点
        antiepidemicPersonList: [],
      }
    },
    async mounted () {
      this.job = sessionStorage.getItem('job')

      // 获取 全旗 动防站点
      await this.getSiteList()
    },
    methods: {
      // 获取 全旗 动防站点
      async getSiteList() {
        let res = await getSiteList()
        // debugger
        try {
          if (res.code == 1000) {
            let isShowMoNi = window.sessionStorage.getItem('isShowMoNi')
            if (isShowMoNi) {
              this.siteList = res.data
            } else {
              this.siteList = res.data.filter(item => item.name != '模拟防疫站')
            }
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 动防站下的 防疫员列表
      async getAntiepidemicPersonList(siteId) {
        let res = await getAntiepidemicPersonList(siteId, this.jobId);
        try {
          if (res.code == 1000) {
            // 防疫员 列表中 不显示 站长
            this.antiepidemicPersonList = res.data.filter(item => item.isType == 0)
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      // 跳转到防疫员页面
      goToAntiepidemicPersonListPage(id) {
        this.$router.push(`/fangYiYuanPage/${id}`)
      },

      // 跳转到 动防站点 页面
      goToSite(id, name, headId) {
        this.$router.push(`/zhanDianPage/${id}/${name}/${headId}`)
      },

      // 跳转到 【防疫员】 页面
      goToFangYiYaunListPage() {
        this.$router.push(`/fangYiYuanList`)
      },

      // 监听 鼠标经过 动防站 名称
      handleMouseoverSiteName(site_index, siteId) {
        this.activeIndex = site_index

        this.getAntiepidemicPersonList(siteId)
      },
    }
  }
</script>

<style>
  .tps.el-tooltip__popper {
    background-color: #002B67 !important; background-clip: .5;
    padding: 0 !important;
    border: 1px solid #002B67;
  }
</style>
