import axios from 'axios'
import { baseURL } from "@/const"
import { Message } from 'element-ui'

const baseurl = baseURL

axios.defaults.timeout = 5000

const service = axios.create({
  baseURL: baseurl,
  timeout: 10000, 
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
})

// 请求
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error) 
    return Promise.reject(error)
  }
)

// 响应
service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code !== '') {
      const tokenerr = [401, 500, '401', '500']
      const codeStatus = [12028, 12029, '12028', '12029']

      if (tokenerr.includes(res.code)) {
        Message({
          message: res.msg,
          type: 'success',
          duration: 1 * 1000
        })
      } else if (codeStatus.includes(res.code)) {
        Message({
          message: res.desc,
          type: 'success',
          duration: 1 * 1000
        })
      } else if (res.code == 500) {
        Message({
          message: res.msg,
          type: 'success',
          duration: 1 * 1000
        })
      } else {
        return res
      }
    } else {
      Message({
        message: res.msg,
        type: 'success',
        duration: 1 * 1000
      })
      return res
    }
  },
  error => {
    if (!error.message.includes('timeout')) {
      Message({
        message: '服务器暂无响应，请稍后重试',
        type: 'success',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
